import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';
import { initializeRouter, registerApplicationRoutes } from 'controller/router';
import { resolvables } from 'controller/resolvables';
import { initializeStore, persistStore } from 'controller/store';
import { migrateStyles } from 'controller/tpa-styles-migration';
import { bindViewModel, initializeViewModel } from 'controller/view-model';

import type { IRootStore } from 'store/types';
import { actions } from 'store/application';

import settingsParams from '../settingsParams';

import { resolve } from './resolve';
import { initializeIntegrations } from './config';

export const controller: CreateControllerFn = function (params) {
  let store: IRootStore;
  const { flowAPI } = params;
  const { wixCodeApi } = params.controllerConfig;

  const router = initializeRouter(params);

  flowAPI.bi?.updateDefaults({ userEntry: 'site' });

  return {
    async pageReady() {
      store = initializeStore(params);
      const vm = initializeViewModel(router, store, params);

      await initializeIntegrations(vm);

      registerApplicationRoutes(router, vm, store, params);
      migrateStyles(params);
      bindViewModel(vm, store, params);
      bindRouter(router, params.flowAPI);

      await resolvables(params, resolve(vm, store, params));
      persistStore(store, params);

      wixCodeApi.user.onLogin(() => resolve(vm, store, params));
    },
    updateConfig() {
      store?.dispatch(
        actions.setAppData({
          groupId: flowAPI.settings.get(settingsParams.groupId),
        }),
      );
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
  };
};
